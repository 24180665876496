<template>
  <div class="dispatch-trip-tasks card">
    <div class="card-content">
      <div
        class="trip-title"
        v-html="title"/>
      <div class="trip-tags">
        <b-tag
          v-if="deliveryTasksCount > 0"
          type="is-info">
          {{ $tc('packAndDispatch.table.tasks.deliveries', deliveryTasksCount, { count: deliveryTasksCount }) }}
        </b-tag>
        <b-tag
          v-if="pickupTasksCount > 0"
          type="is-info">
          {{ $tc('packAndDispatch.table.tasks.pickups', pickupTasksCount, { count: pickupTasksCount }) }}
        </b-tag>
      </div>
      <div
        v-for="group in groupedTasks"
        :key="`trip_group_${group.name}`"
        class="trip-group">
        <div
          class="trip-group-title"
          v-html="groupTitle(group)"/>
        <div
          v-for="task in group.tasks"
          :key="`trip_group_task_${task.id}`"
          class="trip-task">
          <div class="trip-task-order-number">
            <template v-if="task.externalLogisticsId">
              {{ $t(`common.task.shortId.${task.externalLogisticsType || 'pickupp'}`, { shortId: task.shortId }) }}
              •
            </template>
            {{ task.orderIdentifier }}
            <b-tag
              v-if="task.isCancelled"
              class="is-danger">
              {{ $t('tasks.status.cancelled') }}
            </b-tag>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import groupBy from 'lodash/groupBy'

export default {
  name: 'dispatch-trip-tasks',
  props: {
    trip: {
      type: Object,
      default: null
    }
  },
  computed: {
    title() {
      const specialist = (this.trip.courier && this.trip.courier.name) || ''
      return this.$t('packAndDispatch.trip.title', { specialist, tripNum: this.trip.id })
    },
    deliveryTasksCount() {
      return this.trip.tasks.filter(task => !task.isPickup).length
    },
    pickupTasksCount() {
      return this.trip.tasks.filter(task => task.isPickup).length
    },
    groupedTasks() {
      // group tasks by pickup points and delivery
      const groupsMapper = groupBy(this.trip.tasks, 'pickupPointName')
      return Object.keys(groupsMapper).map(key => {
        return {
          name: key === 'null' ? this.$t('packAndDispatch.trip.deliveryGroupLabel') : key,
          tasks: groupsMapper[key]
        }
      })
    }
  },
  methods: {
    groupTitle(group) {
      const count = group.tasks.length
      return this.$tc('packAndDispatch.trip.groupTitle', count, { count, name: group.name })
    }
  }
}
</script>

<style lang="scss" scoped>
.dispatch-trip-tasks {
  .trip-title {
    margin-bottom: $space-xs;
  }

  .trip-tags {
    margin-bottom: $space-s;
  }

  .trip-group {
    background-color: $grey-lighter;

    .trip-group-title {
      padding: $space-xs;
      background-color: $grey-lighter;
    }

    .trip-task {
      .trip-task-order-number {
        padding: $space-xxs $space-xs;

        > .tag {
          margin-left: $space-xxs;
        }
      }
    }
  }
}
</style>
